<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @cancel="onClose" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :label-col="labelCol" :wrapper-col="wrapperCol" :model="form" :rules="rules">
      <a-form-model-item prop="serialNumber">
        <span slot="label">
          <a-tooltip>
            {{ $t('人员测试.板子序列号') }}<template slot="title">{{ $t('人员测试.板子序列号') }}</template>
          </a-tooltip>
        </span>
        <a-input
          v-model="form.serialNumber"
          :maxLength="50"
          :placeholder="$t('通用.输入.请输入') + $t('人员测试.板子序列号')"
        />
      </a-form-model-item>
      <a-form-model-item prop="fixtureId">
        <span slot="label">
          <a-tooltip>
            {{ $t('人员测试.针id') }}<template slot="title">{{ $t('人员测试.针id') }}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.fixtureId" :maxLength="50" :placeholder="$t('通用.输入.请输入') + $t('人员测试.针id')" />
      </a-form-model-item>

      <a-form-model-item prop="fwVersion">
        <span slot="label">
          <a-tooltip>
            {{ $t('人员测试.fw版本号') }}<template slot="title">{{ $t('人员测试.fw版本号') }}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.fwVersion" :maxLength="50" :placeholder="$t('通用.输入.请输入') + $t('人员测试.fw版本号')" />
      </a-form-model-item>

      <a-form-model-item prop="startDate">
        <span slot="label">
          <a-tooltip>
            {{ $t('人员测试.启动时间') }}<template slot="title">{{ $t('人员测试.启动时间') }}</template>
          </a-tooltip>
        </span>
        <a-date-picker style="width: 100%" v-model="form.startDate" format="YYYY-MM-DD HH:mm:ss" valueFormat="YYYY-MM-DD HH:mm:ss" allow-clear />
      </a-form-model-item>
      <a-form-model-item prop="biasVHigh">
        <span slot="label">
          <a-tooltip>
            {{ $t('人员测试.偏置V高') }}<template slot="title">{{ $t('人员测试.偏置V高') }}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.biasVHigh" :placeholder="$t('通用.输入.请输入') + $t('人员测试.偏置V高')" />
      </a-form-model-item>
      <a-form-model-item prop="biasVLow">
        <span slot="label">
          <a-tooltip>
            {{ $t('人员测试.偏置V低') }}<template slot="title">{{ $t('人员测试.偏置V低') }}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.biasVLow" :placeholder="$t('通用.输入.请输入') + $t('人员测试.偏置V低')" />
      </a-form-model-item>
      <a-form-model-item prop="ciHigh">
        <span slot="label">
          <a-tooltip>
            {{ $t('人员测试.I高') }}<template slot="title">{{ $t('人员测试.I高') }}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.ciHigh" :placeholder="$t('通用.输入.请输入') + $t('人员测试.I高')" />
      </a-form-model-item>
      <a-form-model-item prop="ciLow">
        <span slot="label">
          <a-tooltip>
            {{ $t('人员测试.I低') }}<template slot="title">{{ $t('人员测试.I低') }}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.ciLow" :placeholder="$t('通用.输入.请输入') + $t('人员测试.I低')" />
      </a-form-model-item>
      <a-form-model-item prop="ciDraw">
        <span slot="label">
          <a-tooltip>
            {{ $t('人员测试.I画') }}<template slot="title">{{ $t('人员测试.I画') }}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.ciDraw" :placeholder="$t('通用.输入.请输入') + $t('人员测试.I画')" />
      </a-form-model-item>
      <a-form-model-item prop="batteryV">
        <span slot="label">
          <a-tooltip>
            {{ $t('人员测试.电压') }}<template slot="title">{{ $t('人员测试.电压') }}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.batteryV" :placeholder="$t('通用.输入.请输入') + $t('人员测试.电压')" />
      </a-form-model-item>
      <a-form-model-item prop="cpOn">
        <span slot="label">
          <a-tooltip>
            {{ $t('人员测试.是否抽检通过') }}<template slot="title">{{ $t('人员测试.是否抽检通过') }}</template>
          </a-tooltip>
        </span>
        <!--<a-input-->
          <!--v-model="form.cpOn"-->
          <!--:maxLength="10"-->
          <!--:placeholder="$t('通用.输入.请输入') + $t('人员测试.是否抽检通过')"-->
        <!--/>-->

        <a-select
          v-model="form.cpOn"
          style="width: 100%"
          :placeholder="$t('通用.输入.请输入') + $t('人员测试.是否抽检通过')" size="large"
        >
          <a-select-option value="passed">passed</a-select-option>
          <a-select-option value="no_passed">no_passed</a-select-option>
        </a-select>
      </a-form-model-item>

      <a-form-model-item prop="sensitivity">
        <span slot="label">
          <a-tooltip>
            {{ $t('人员测试.校准值') }}<template slot="title">{{ $t('人员测试.校准值') }}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.sensitivity" :placeholder="$t('通用.输入.请输入') + $t('人员测试.校准值')" />
      </a-form-model-item>



      <!--<a-form-model-item prop="remark">-->
        <!--<span slot="label">-->
          <!--<a-tooltip>-->
            <!--{{ $t('人员测试.备注') }}<template slot="title">{{ $t('人员测试.备注') }}</template>-->
          <!--</a-tooltip>-->
        <!--</span>-->
        <!--<a-input v-model="form.remark" :maxLength="100" :placeholder="$t('通用.输入.请输入') + $t('人员测试.备注')" />-->
      <!--</a-form-model-item>-->
      <a-modal :visible="previewImageVisible" :footer="null" @cancel="previewImageCancel">
        <img alt="example" style="width: 100%" :src="previewUrl" />
      </a-modal>
      <a-modal :visible="previewVideoVisible" :footer="null" @cancel="previewVideoCancel">
        <video
          style="width: 100%"
          :src="previewUrl"
          ref="myVideo"
          :poster="previewUrl + '?x-oss-process=video/snapshot,t_0,f_jpg'"
          :controls="true"
        ></video>
      </a-modal>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm" class="searchBtn">
            {{ $t('通用.按钮.保存') }}
          </a-button>
          <a-button type="dashed" @click="cancel">
            {{ $t('通用.按钮.取消') }}
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getPcba, addPcba, updatePcba } from '@/api/pcba/pcba'
import { mapGetters } from 'vuex'
import CustomDictTag from '@/components/DictCustomTag'
import { generateFilePath, uploadObject } from '@/api/tool/oss'

export default {
  name: 'CreateForm',
  props: {},
  components: {
    CustomDictTag,
  },
  data() {
    return {
      labelCol: { span: 5 },
      wrapperCol: { span: 18 },
      submitLoading: false,
      formTitle: '',
      previewImageVisible: false,
      previewUrl: '',
      previewVideoVisible: false,
      loading: '',
      // 表单参数
      form: {
        id: null,

        serialNumber: null,

        fixtureId: null,

        startDate: null,

        biasVHigh: null,

        biasVLow: null,

        ciHigh: null,

        ciLow: null,

        ciDraw: null,

        batteryV: null,

        cpOn: null,

        createTime: null,

        remark: null,

        sensitivity: null,

        fwVersion: null,
      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        serialNumber: [
          { required: true, message: this.$t('人员测试.板子序列号') + this.$t('通用.文本.不能为空'), trigger: 'blur' },
        ],
        // fixtureId: [
        //   { required: true, message: this.$t('人员测试.针id') + this.$t('通用.文本.不能为空'), trigger: 'blur' },
        // ],
      },
    }
  },
  filters: {},
  created() {},
  computed: {
    ...mapGetters(['customDict']),
  },
  watch: {},
  mounted() {},
  methods: {
    onClose() {
      this.open = false
      this.reset()
    },
    // 取消按钮
    cancel() {
      this.open = false
      this.reset()
    },
    showModel() {
      this.open = true
    },
    previewImageCancel() {
      this.previewImageVisible = false
    },
    previewVideoCancel() {
      this.previewVideoVisible = false
    },
    // 表单重置
    reset() {
      this.formType = 1
      this.form = {
        id: null,
        serialNumber: null,
        fixtureId: null,
        startDate: null,
        biasVHigh: null,
        biasVLow: null,
        ciHigh: null,
        ciLow: null,
        ciDraw: null,
        batteryV: null,
        cpOn: null,
        createTime: null,
        remark: null,
      }
    },
    /** 新增按钮操作 */
    handleAdd(row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = this.$t('通用.文本.添加')
    },
    /** 修改按钮操作 */
    handleUpdate(row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getPcba({ id: id }).then((response) => {
        this.form = response.data
        this.open = true
        this.formTitle = this.$t('通用.文本.修改')
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.submitLoading = true
          let message = ''
          if (this.form.id !== undefined && this.form.id !== null) {
            message = this.$t('通用.文本.修改成功')
            updatePcba(this.form)
              .then((response) => {
                this.$message.success(message, 3)
                this.open = false
                this.$emit('ok')
              })
              .finally(() => {
                this.submitLoading = false
              })
          } else {
            message = this.$t('通用.文本.新增成功')
            addPcba(this.form)
              .then((response) => {
                this.$message.success(message, 3)
                this.open = false
                this.$emit('ok')
              })
              .finally(() => {
                this.submitLoading = false
              })
          }
        } else {
          return false
        }
      })
    },
  },
}
</script>

<style scoped lang="less">
.searchBtn {
  color: #fff;
  background-color: rgb(127, 98, 173);
  font-weight: 500;
  font-size: 16px;

  border-radius: 0.25rem;

  border: none !important;
}
</style>
