<template>
  <!-- <page-header-wrapper> -->
  <div class="content">
    <a-card style="border-radius: 4px; margin-bottom: 16px">
      <div class="top-title">
        <!-- <a-icon type="smile" style="font-size: 38px; color: rgb(127, 98, 173); margin-right: 20px" /> -->
        <svg
          aria-hidden="true"
          focusable="false"
          data-prefix="fas"
          data-icon="microchip"
          class="svg-inline--fa fa-microchip fa-fw fa-lg"
          role="img"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 512 512"
          width="32px"
          height="32px"
          style="margin-right: 5px"
        >
          <path
            fill="currentColor"
            d="M176 24c0-13.3-10.7-24-24-24s-24 10.7-24 24V64c-35.3 0-64 28.7-64 64H24c-13.3 0-24 10.7-24 24s10.7 24 24 24H64v56H24c-13.3 0-24 10.7-24 24s10.7 24 24 24H64v56H24c-13.3 0-24 10.7-24 24s10.7 24 24 24H64c0 35.3 28.7 64 64 64v40c0 13.3 10.7 24 24 24s24-10.7 24-24V448h56v40c0 13.3 10.7 24 24 24s24-10.7 24-24V448h56v40c0 13.3 10.7 24 24 24s24-10.7 24-24V448c35.3 0 64-28.7 64-64h40c13.3 0 24-10.7 24-24s-10.7-24-24-24H448V280h40c13.3 0 24-10.7 24-24s-10.7-24-24-24H448V176h40c13.3 0 24-10.7 24-24s-10.7-24-24-24H448c0-35.3-28.7-64-64-64V24c0-13.3-10.7-24-24-24s-24 10.7-24 24V64H280V24c0-13.3-10.7-24-24-24s-24 10.7-24 24V64H176V24zM160 128H352c17.7 0 32 14.3 32 32V352c0 17.7-14.3 32-32 32H160c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32zm192 32H160V352H352V160z"
          ></path>
        </svg>
        <!-- PCBA Test Results -->
        {{ $t('人员测试.PCBA 测试结果') }}
      </div>
      <div class="top-middle">
        <!-- Display all recorded CGM raw values and the glucose concentration (SMBG), which were measured with the
        associated glucometer. -->
        {{ $t('人员测试.程序设计站的结果。') }}
      </div>
      <div>
        <a-button class="btn" @click="handleExport">
          <!-- Report  -->
          {{ $t('人员测试.报告') }}
          <!-- <a-icon type="download" style="font-size: 20px; color: rgb(127, 98, 173); margin-right: 20px" /> -->
          <svg
            aria-hidden="true"
            focusable="false"
            data-prefix="fas"
            data-icon="download"
            class="svg-inline--fa fa-download fa-fw"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
            width="20px"
            height="16px"
          >
            <path
              fill="currentColor"
              d="M288 32c0-17.7-14.3-32-32-32s-32 14.3-32 32V274.7l-73.4-73.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l128 128c12.5 12.5 32.8 12.5 45.3 0l128-128c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L288 274.7V32zM64 352c-35.3 0-64 28.7-64 64v32c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V416c0-35.3-28.7-64-64-64H346.5l-45.3 45.3c-25 25-65.5 25-90.5 0L165.5 352H64zm368 56a24 24 0 1 1 0 48 24 24 0 1 1 0-48z"
            ></path>
          </svg>
        </a-button>
        <a-button class="btn btns">
          <!-- Show diagram widgets -->
          {{ $t('人员测试.显示图表小部件') }}
          <!-- <a-icon type="line-chart" style="font-size: 20px; color: rgb(127, 98, 173); margin-right: 20px" /> -->
          <svg
            aria-hidden="true"
            focusable="false"
            data-prefix="fas"
            data-icon="chart-area"
            class="svg-inline--fa fa-chart-area fa-fw"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
            width="20px"
            height="16px"
            style="margin-left:5px;"
          >
            <path
              fill="currentColor"
              d="M64 64c0-17.7-14.3-32-32-32S0 46.3 0 64V400c0 44.2 35.8 80 80 80H480c17.7 0 32-14.3 32-32s-14.3-32-32-32H80c-8.8 0-16-7.2-16-16V64zm96 288H448c17.7 0 32-14.3 32-32V251.8c0-7.6-2.7-15-7.7-20.8l-65.8-76.8c-12.1-14.2-33.7-15-46.9-1.8l-21 21c-10 10-26.4 9.2-35.4-1.6l-39.2-47c-12.6-15.1-35.7-15.4-48.7-.6L135.9 215c-5.1 5.8-7.9 13.3-7.9 21.1v84c0 17.7 14.3 32 32 32z"
            ></path>
          </svg>
        </a-button>
      </div>
    </a-card>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <!-- <a-form layout="inline"> -->
        <a-row :gutter="34">
          <a-col :md="2" :sm="24" style="margin-top: 4px">
            <a-button class="addBtn" @click="$refs.createForm.handleAdd()"
              >{{ $t('通用.按钮.新增') }} <a-icon type="plus"
            /></a-button>
          </a-col>
          <a-col :md="6" :sm="24" style="margin-top: 4px">
            <a-radio-group v-model="value1" button-style="solid" @change="radioChange" class="radioChangeClass">
              <a-radio-button value="1">{{ $t('人员测试.全部') }}({{ pcbaStat.allPcba }})</a-radio-button>
              <a-radio-button value="2">{{ $t('人员测试.通过') }}({{ pcbaStat.passedPcba }})</a-radio-button>
              <a-radio-button value="3">{{ $t('人员测试.未通过') }}({{ pcbaStat.noPassedPcba }})</a-radio-button>
            </a-radio-group>
          </a-col>
          <a-col :md="6" :sm="24">
            <a-form-item prop="serialNumber">
              <!-- <a-input
                  v-model="queryParam.serialNumber"
                  :maxLength="32"
                  :placeholder="$t('通用.输入.请输入') + $t('人员测试.板子序列号')"
                  allow-clear
                  @focus="getfocus"
                  @blur="losefocus"
                />
                <ul v-if="memoryList.length">
                  <li v-for="(memory, index) in memoryList" :key="index" @click="selectSuggestion(memory)">{{ memory.searchWord }}</li>
                </ul> -->

              <a-select
                show-search
                v-model="queryParam.serialNumber"
                style="width: 100%"
                placeholder="请输入"
                option-filter-prop="children"
                :filter-option="filterOption"
                @focus="getfocus"
                @change="selectChange"
              >
                <a-select-option v-for="(item, index) in memoryList" :key="index">
                  {{ item.searchWord }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="24">
            <a-form-item prop="fixtureId">
              <a-input
                v-model="queryParam.fixtureId"
                :maxLength="32"
                :placeholder="$t('通用.输入.请输入') + $t('人员测试.ID')"
                allow-clear
              />
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="30">
          <a-col :md="6" :sm="24">
            <a-form-item prop="startBeginDate">
              <a-date-picker
                :placeholder="$t('系统用户.开始时间')"
                style="width: 100%"
                v-model="queryParam.startBeginDate"
                :show-time="{ defaultValue: moment('00:00:00', 'HH:mm:ss') }"
                valueFormat="YYYY-MM-DD HH:mm:ss"
                allow-clear
              />
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="24">
            <a-form-item prop="startEndDate">
              <a-date-picker
                :placeholder="$t('系统用户.结束时间')"
                style="width: 100%"
                v-model="queryParam.startEndDate"
                :show-time="{ defaultValue: moment('23:59:59', 'HH:mm:ss') }"
                valueFormat="YYYY-MM-DD HH:mm:ss"
                allow-clear
              />
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="24"> </a-col>
          <a-col :md="(!advanced && 6) || 24" :sm="24" style="text-align: right">
            <span
              class="table-page-search-submitButtons"
              :style="(advanced && { float: 'right', overflow: 'hidden' }) || {}"
              style="margin-top: 4px"
            >
              <a-button @click="handleQuery" class="searchBtn"
                ><a-icon type="search" />{{ $t('通用.按钮.查询') }}</a-button
              >
              <a-button style="margin-left: 8px" @click="resetQuery" class="resetBtn"
                ><a-icon type="redo" />{{ $t('通用.按钮.重置') }}</a-button
              >
            </span>
          </a-col>
        </a-row>
        <!-- </a-form> -->
        <a-modal :visible="previewImageVisible" :footer="null" @cancel="previewImageCancel">
          <img alt="example" style="width: 100%" :src="previewUrl" />
        </a-modal>
      </div>
      <!-- 操作 -->
      <!--<div class="table-operations">-->
      <!--<a-button type="primary" @click="$refs.createForm.handleAdd()" v-hasPermi="['pcba:pcba:add']" class="searchBtn">-->
      <!--<a-icon type="plus" />{{ $t('通用.按钮.新增') }}-->
      <!--</a-button>-->
      <!--<a-button-->
      <!--type="primary"-->
      <!--:disabled="single"-->
      <!--@click="$refs.createForm.handleUpdate(undefined, ids)"-->
      <!--v-hasPermi="['pcba:pcba:edit']"-->
      <!--&gt;-->
      <!--<a-icon type="edit" />{{ $t('通用.按钮.修改') }}-->
      <!--</a-button>-->
      <!--<a-button type="danger" :disabled="multiple" @click="handleDelete" v-hasPermi="['pcba:pcba:remove']">-->
      <!--<a-icon type="delete" />{{ $t('通用.按钮.删除') }}-->
      <!--</a-button>-->
      <!--<a-button type="primary" @click="handleExport" v-hasPermi="['pcba:pcba:export']" class="searchBtn">-->
      <!--<a-icon type="download" />{{ $t('通用.按钮.导出') }}-->
      <!--</a-button>-->
      <!--<table-setting-->
      <!--:style="{ float: 'right' }"-->
      <!--:table-size.sync="tableSize"-->
      <!--v-model="columns"-->
      <!--:refresh-loading="loading"-->
      <!--@refresh="getList"-->
      <!--/>-->
      <!--</div>-->
      <!-- 增加修改 -->
      <create-form ref="createForm" @ok="getList" />
      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        @change="handleSortChange"
        rowKey="id"
        :columns="columns"
        :data-source="list"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        :pagination="false"
        :bordered="tableBordered"
      >
        <span slot="serialNumber" slot-scope="text, record" @click="handlerowclick(record)">
          {{ record.serialNumber }}
        </span>

        <span slot="startDate" slot-scope="text, record">
          {{ parseTime(record.startDate) }}
        </span>
        <span slot="createTime" slot-scope="text, record">
          {{ parseTime(record.createTime) }}
        </span>
        <span slot="operation" slot-scope="text, record">
          <a-divider type="vertical" />
          <!--  @click="$refs.createForm.handleUpdate(record, undefined)" -->
          <a @click.stop="$refs.createForm.handleUpdate(record, undefined)" style="color: rgb(127, 98, 173)">
            <a-icon type="edit" />{{ $t('通用.按钮.修改') }}
          </a>
          <a-divider type="vertical" />
          <a @click.stop="handleDelete(record)" style="color: red">
            <a-icon type="delete" />{{ $t('通用.按钮.删除') }}
          </a>
        </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :pageSizeOptions="pageSizeOptions"
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="(total) => getTotal(total)"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>
  </div>

  <!-- </page-header-wrapper> -->
</template>

<script>
import { pagePcba, listPcba, delPcba, queryPcbaStat } from '@/api/pcba/pcba'
import CustomDictTag from '@/components/DictCustomTag'
import { mapGetters } from 'vuex'
import moment from 'moment'
import CreateForm from './modules/CreateForm'
import { tableMixin } from '@/store/table-mixin'
import { pageMemory, listMemory, delMemory, listSixMemory } from '@/api/memory/memory'

export default {
  name: 'Pcba',
  components: {
    CustomDictTag,
    CreateForm,
  },
  mixins: [tableMixin],
  data() {
    return {
      value1: '1',
      pcbaStat: {},
      list: [],
      pageSizeOptions: ['10', '20', '50', '100'],
      filteredInfo: {},
      sortedInfo: {},
      selectedRowKeys: [],
      selectedRows: [],
      memoryList: [],
      //预览封面
      previewImageVisible: false,
      previewUrl: false,
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 查询参数
      queryParam: {
        serialNumber: '',
        fixtureId: undefined,
        startDate: undefined,
        biasVHigh: undefined,
        biasVLow: undefined,
        ciHigh: undefined,
        ciLow: undefined,
        ciDraw: undefined,
        batteryV: undefined,
        cpOn: undefined,
        pageNum: 1,
        pageSize: 10,
        startBeginDate: undefined,
        startEndDate: undefined,
      },
      columns: [
        // {
        //   title: this.$t('人员测试.id'),
        //   dataIndex: 'id',
        //   ellipsis: true,
        //   align: 'center',
        // },
        {
          title: this.$t('人员测试.板子序列号'),
          dataIndex: 'serialNumber',
          ellipsis: true,
          align: 'center',
          width: '200px',
          sorter: true,
          scopedSlots: { customRender: 'serialNumber' },
        },
        {
          title: this.$t('人员测试.针id'),
          dataIndex: 'fixtureId',
          ellipsis: true,
          align: 'center',
          sorter: true,
        },
        {
          title: this.$t('人员测试.启动时间'),
          dataIndex: 'startDate',
          scopedSlots: { customRender: 'startDate' },
          ellipsis: true,
          align: 'center',
          sorter: true,
        },
        {
          title: this.$t('人员测试.偏置V高'),
          dataIndex: 'biasVHigh',
          ellipsis: true,
          align: 'center',
          sorter: true,
        },
        {
          title: this.$t('人员测试.偏置V低'),
          dataIndex: 'biasVLow',
          ellipsis: true,
          align: 'center',
          sorter: true,
        },
        {
          title: this.$t('人员测试.I高'),
          dataIndex: 'ciHigh',
          ellipsis: true,
          align: 'center',
          sorter: true,
        },
        {
          title: this.$t('人员测试.I低'),
          dataIndex: 'ciLow',
          ellipsis: true,
          align: 'center',
          sorter: true,
        },
        {
          title: this.$t('人员测试.I画'),
          dataIndex: 'ciDraw',
          ellipsis: true,
          align: 'center',
          sorter: true,
        },
        {
          title: this.$t('人员测试.电压'),
          dataIndex: 'batteryV',
          ellipsis: true,
          align: 'center',
          sorter: true,
        },
        {
          title: this.$t('人员测试.是否抽检通过'),
          dataIndex: 'cpOn',
          ellipsis: true,
          align: 'center',
          sorter: true,
        },
        // {
        //   title: this.$t('人员测试.'),
        //   dataIndex: 'createTime',
        //   scopedSlots: { customRender: 'createTime' },
        //   ellipsis: true,
        //   align: 'center'
        // },
        // {
        //   title: this.$t('人员测试.备注'),
        //   dataIndex: 'remark',
        //   ellipsis: true,
        //   align: 'center'
        // },
        {
          title: this.$t('通用.文本.操作'),
          dataIndex: 'operation',
          width: '18%',
          scopedSlots: { customRender: 'operation' },
          align: 'center',
        },
      ],
    }
  },
  filters: {},
  created() {
    this.getList()
    //this.sixMemoryList();
  },
  computed: {
    ...mapGetters(['customDict']),
  },
  watch: {},
  methods: {
    moment,
    getTotal(total) {
      return this.$t('通用.文本.共') + total + this.$t('通用.文本.条')
    },

    /** 查询页面统计数据 */
    queryPcbaStat() {
      this.loading = true
      queryPcbaStat(this.queryParam).then((response) => {
        this.pcbaStat = response.data
      })
    },

    /** 查询人员测试列表 */
    getList() {
      this.loading = true
      pagePcba(this.queryParam).then((response) => {
        this.list = response.data.records
        this.total = response.data.total
        this.loading = false
      })

      //查询数据统计
      this.queryPcbaStat()
    },

    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParam.pageNum = 1
      this.getList()
    },
    changePOn(value) {
      if (value == 1) {
        this.queryParam.cpOn = ''
      } else if (value == 2) {
        this.queryParam.cpOn = 'passed'
      } else if (value == 3) {
        this.queryParam.cpOn = 'no_passed'
      }
      this.handleQuery()
    },

    /** 重置按钮操作 */
    resetQuery() {
      this.queryParam = {
        serialNumber: undefined,
        fixtureId: undefined,
        startDate: undefined,
        biasVHigh: undefined,
        biasVLow: undefined,
        ciHigh: undefined,
        ciLow: undefined,
        ciDraw: undefined,
        batteryV: undefined,
        cpOn: undefined,
        pageNum: 1,
        pageSize: 10,
      }
      this.handleQuery()
    },
    handleSortChange(pagination, filters, sorter) {
      this.filteredInfo = filters
      this.sortedInfo = sorter
      if (sorter.order) {
        /** 排序字段 sortField **/
        /** 排序方式  sortOrder**/
        this.queryParam.sortField = sorter.columnKey
        if (this.queryParam.sortField === 'createTime') {
          this.queryParam.sortField = 'id'
        }
        this.queryParam.sortOrder = sorter.order === 'ascend' ? 'asc' : 'desc'
        this.getList()
      } else {
        this.queryParam.sortField = ''
        this.queryParam.sortOrder = ''
        this.getList()
      }
    },
    onShowSizeChange(current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize(current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map((item) => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    previewImageCancel() {
      this.previewImageVisible = false
    },
    handleRecordPreview(url) {
      this.previewUrl = url
      this.previewImageVisible = true
    },
    toggleAdvanced() {
      this.advanced = !this.advanced
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      var that = this
      const ids = row.id || this.ids
      if (row.id) {
        this.ids = []
        this.ids.push(row.id)
      }
      this.$confirm({
        title: this.$t('通用.文本.确认删除所选中数据'),
        onOk() {
          let data = []
          that.ids.map((id) => data.push({ id: id }))
          return delPcba(data).then(() => {
            that.onSelectChange([], [])
            that.getList()
            const message = that.$t('通用.文本.删除成功')
            that.$message.success(message, 3)
          })
        },
        onCancel() {
          //取消清空删除ids列表
          that.ids = []
        },
        okButtonProps: { style: { backgroundColor: '#7f62ad', color: '#fff', borderColor: '#7f62ad' } },
        cancelButtonProps: { style: { color: '#7f62ad', backgroundColor: '#fff', borderColor: '#7f62ad' } },
      })
    },
    /** 导出按钮操作 */
    handleExport() {
      var that = this
      let filename = that.queryParam.serialNumber
      this.$confirm({
        title: this.$t('通用.文本.是否确认导出'),
        content: this.$t('通用.文本.此操作将导出当前条件下所有数据而非选中数据'),
        onOk() {
          that.download('pcba/pcba/export', that.queryParam, `pcba${new Date().getTime()}.csv`)
        },
        onCancel() {},
        okButtonProps: {
          style: 'background-color: rgb(127, 98, 173); color: white;border:none', // 自定义确认按钮的样式
        },
      })
    },
    // radio 按钮 点击
    radioChange(e) {
      this.changePOn(e.target.value)
    },
    handlerowclick(record) {
      this.$router.push({
        path: '/sensor/sub-sensor',
        query: {
          serialNumber: record.serialNumber,
        },
      })
      //   return {
      //     on: {
      //       click: () => {
      //       //行单击
      //       this.$router.push({
      //       path: '/sensor/sub-sensor',
      //       query: {
      //         serialNumber: record.serialNumber,
      //       },
      //     })
      //   },
      // },
      // }
    },

    //获得焦点
    getfocus() {
      this.sixMemoryList()
    },
    //失去焦点
    losefocus() {
      this.memoryList = []
    },
    //查询联词搜索
    sixMemoryList() {
      let param = {}
      param.meunType = 'PCBA'
      listSixMemory(param).then((response) => {
        this.memoryList = response.data
      })
    },
    selectSuggestion(memory) {
      console.log(memory)
      //queryParam.serialNumber
      // 执行其他需要的操作，例如提交表单或者进行搜索
    },
    selectChange(e) {
      //  console.log('下拉',e)
      this.queryParam.serialNumber = this.memoryList[e].searchWord
    },
    filterOption(input, option) {
      this.queryParam.serialNumber = input

      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
    // handleAdd(){
    //      this.$refs.createForm.handleAdd()
    // }
  },
}
</script>

<style scoped lang="less">
.content {
  width: 100%;
  height: 100%;

  .top-title {
    align-items: center;
    display: flex;
    font-size: 1.6rem;
    font-weight: 600;
    color: #212529;
  }

  .top-middle {
    margin-top: 8px;
    font-size: 16px;
    color: #212529;
    font-family: Poppins, sans-serif;
  }

  .btn {
    color: rgb(127, 98, 173);
    //    background-color: rgb(127, 98, 173);
    font-weight: 500;
    font-size: 16px;
    margin-top: 16px;
    //    border-radius: .25rem;
    // width: 126px;
    height: 38px;
    //    color: #fff;
    border: none !important;
  }
  .btns {
    // width: 156px;
    // display: flex;
    align-items: center;
  }
}

.searchBtn {
  color: #fff;
  background-color: rgb(127, 98, 173);
  font-weight: 500;
  font-size: 16px;
  //    margin-top: 16px;
  border-radius: 0.25rem;
  //    width: 126px;
  //    height: 38px;
  //    border-radius: 4px;
  //    color: #fff;
  border: none !important;
}

.resetBtn {
  border-radius: 0.25rem;
  font-weight: 500;
  font-size: 16px;
}

.radioChangeClass {
  border-radius: 0.25rem !important;

  .ant-radio-button-wrapper {
    color: rgb(127, 98, 173) !important;
    border-color: rgb(127, 98, 173) !important;
  }
  .ant-radio-button-wrapper-checked {
    background-color: rgb(127, 98, 173) !important;
    color: #fff !important;
  }
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
    background-color: rgb(127, 98, 173) !important;
  }
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
  z-index: 10000;
}

li {
  cursor: pointer;
  padding: 5px;
  background: #f0f0f0;
  margin-top: 2px;
  position: relative;
  z-index: 10000;
}

li:hover {
  background: #e0e0e0;
}

.addBtn {
  color: #fff;
  background-color: rgb(127, 98, 173);
  font-weight: 500;
  font-size: 16px;
  margin-top: 16px;
  border-radius: 0.25rem;
  border: 1px solid rgb(127, 98, 173);
  margin-top: 0px !important;
}
</style>
